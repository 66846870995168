<template>
  <div>
    <el-card shadow="never" class="card-custom-all">
      <p class="name-head">แก้ไขเกณฑ์ในการพิจารณา</p>
      <el-row :gutter="30">
        <el-col :span="24" :md="12" class="box-set-tooltip">
          <el-form-item
            label="ขนาดธุรกิจ "
            class="mg-0 is-required"
            prop="insuranceSize"
          >
            <el-checkbox v-model="formData.insuranceSizeS">Size S</el-checkbox>
            <el-checkbox v-model="formData.insuranceSizeM">Size M</el-checkbox>
            <el-checkbox v-model="formData.insuranceSizeL">Size L</el-checkbox>
          </el-form-item>

          <el-tooltip
            class="item font-14 custom-tooltip"
            effect="dark"
            placement="bottom-start"
          >
            <div slot="content">
              <span>
                ลักษณะธุรกิจ <br />
                1. ธุรกิจการผลิต คำนวนขนาดธุรกิจจาก สสว. ใหม่ ใช้เกณฑ์ <br />
                - SIZE S 0 - 100 ล้านบาท <br />
                - SIZE M มากกว่า 100 - 500 ล้านบาท <br />
                - SIZE L มากกว่า 500 ล้านบาทขึ้นไป <br />
                2. ธุรกิจบริการ และ ธุรกิจแบบซื้อมาขายไป คำนวนขนาดธุรกิจจาก สสว.
                ใหม่ ใช้เกณฑ์ <br />
                - SIZE S 0 - 50 ล้านบาท <br />
                - SIZE M มากกว่า 50 - 300 ล้านบาท <br />
                - SIZE L มากกว่า 300 ล้านบาทขึ้นไป
              </span>
            </div>
            <i class="fas fa-question-circle"></i>
          </el-tooltip>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="วงเงินค้าขายต่อ Shipment">
            <el-select
              clearable
              id="formDataShipment"
              v-model="formData.shipment"
              placeholder="เลือกวงเงินค้าขายต่อ Shipment"
              @change="sendData"
              multiple
              default-first-option
              popper-class="custom-select"
            >
              <el-option
                v-for="item in optionsShipment"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <div class="is-flex ai-center">
                  <div class="circle-select"></div>
                  {{ item.label }}
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="มูลค่าการส่งออก">
            <el-select
              clearable
              id="formDataExportValue"
              v-model="formData.exportValue"
              placeholder="มูลค่าการส่งออก"
              @change="sendData"
              multiple
              default-first-option
              popper-class="custom-select"
            >
              <el-option
                v-for="item in optionsExportValue"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <div class="is-flex ai-center">
                  <div class="circle-select"></div>
                  {{ item.label }}
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="เทอมการชำระเงิน">
            <el-select
              clearable
              id="formDataPaymentTerm"
              v-model="formData.term"
              placeholder="เทอมการชำระเงิน"
              @change="sendData"
              multiple
              default-first-option
              popper-class="custom-select"
            >
              <el-option
                v-for="item in optionsPaymentTerm"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <div class="is-flex ai-center">
                  <div class="circle-select"></div>
                  {{ item.label }}
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24" :md="12">
          <el-form-item label="โครงการจากระบบ TERAK" prop="expTERAK">
            <el-select
              clearable
              filterable
              multiple
              id="formDataProjectTERAK"
              v-model="formData.projectTERAK"
              placeholder="เลือกโครงการจากระบบ TERAK"
              @change="selectTERAK"
              default-first-option
              popper-class="custom-select"
            >
              <el-option
                v-for="(item, index) in optionsRef"
                :key="index"
                :label="
                  item.expire
                    ? item.projectName + ' (หมดอายุ)'
                    : item.projectName
                "
                :value="item.id"
              >
                <div class="is-flex ai-center">
                  <div
                    class="circle-select"
                    :class="{ 'color-danger': item.expire }"
                  ></div>
                  <div :class="{ 'color-danger': item.expire }">
                    {{ item.projectName }}
                    <span v-if="item.expire" class="mg-l-7"> (หมดอายุ)</span>
                  </div>
                </div>
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
import { TERAK } from "@/service/axios";
import { HTTP } from "@/service/axios";
import { mapState } from "vuex";
import crypto from "crypto";
import moment from "moment";
const algorithm = "aes-256-ctr";
const secretKey = "vwPH9sdmpnVjrTIQCc7qSXs11lWhzFrT";
const iv = crypto.randomBytes(16);

export default {
  props: ["formData"],
  mounted() {
    this.setData();
  },
  watch: {
    formData() {
      this.setData();
    },
  },
  data() {
    return {
      constSelect2: [],
      optionsRef: [],
      loading: true,
      optionsShipment: [
        {
          value: 1,
          label: "น้อยกว่า 0.5 ล้านบาท",
        },
        {
          value: 2,
          label: "0.5 - 1 ล้านบาท",
        },
        {
          value: 3,
          label: "1 ล้านบาทขึ้นไป",
        },
      ],
      optionsExportValue: [
        { value: 1, label: "ไม่เกิน 100 ล้านบาท" },
        { value: 2, label: "101-200 ล้านบาท" },
        { value: 3, label: "201-300 ล้านบาท" },
        { value: 4, label: "301-400 ล้านบาท" },
        { value: 5, label: "401-500 ล้านบาท" },
        { value: 6, label: "มากกว่า 500 ล้านบาท" },
      ],
      optionsPaymentTerm: [
        {
          value: 90,
          label: "ไม่เกิน 90 วัน",
        },
        {
          value: 120,
          label: "ไม่เกิน 120 วัน",
        },
        {
          value: 180,
          label: "ไม่เกิน 180 วัน",
        },
      ],
      delay: null,
      disabledChannel: false,
    };
  },
  methods: {
    setData() {
      this.optionsRef = [];
      this.getProjectByTerak("CFOE2021");
    },
    getProjectByTerak(text) {
      try {
        var ivstring = iv.toString("hex").slice(0, 16);
        const cipher = crypto.createCipheriv(algorithm, secretKey, ivstring);
        const encrypted = Buffer.concat([cipher.update(text), cipher.final()]);

        let obj = {
          iv: ivstring.toString("hex"),
          content: encrypted.toString("hex"),
        };
        TERAK.defaults.headers.common.Authorization = `Bearer ${obj.iv +
          ":" +
          obj.content}`;
        this.optionsRef = [];
        clearTimeout(this.delay);
        this.delay = setTimeout(() => {
          TERAK.get(`cfoe/refs`)
            .then((res) => {
              if (res.data.success) {
                res.data.obj.forEach((item) => {
                  let expire = false;
                  // let dateNow = moment(new Date()).format("yyyy-MM-DD");
                  // let dateEnd = moment(item.endDate).format("yyyy-MM-DD");
                  let index = this.formData.projectTERAK.findIndex(
                    (a) => a == item.id
                  );
                  // if (dateNow > dateEnd) {
                  //   expire = true;
                  // }
                  !expire || index > -1
                    ? this.optionsRef.push({
                        ...item,
                        expire: expire,
                      })
                    : "";
                });
                this.optionsRef.unshift({
                  id: Number("999"),
                  expire: false,
                  projectName: "ทั้งหมด",
                });

                if (
                  this.formData.projectTERAK.length ==
                  this.optionsRef.length - 1
                ) {
                  this.formData.projectTERAK.unshift(999);
                }
                this.constSelect2 = JSON.parse(
                  JSON.stringify(this.formData.projectTERAK)
                );

                this.$emit("optionsOut", this.optionsRef);
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }, 800);
      } catch (e) {
        console.log("Error: encrypt", e);
        return false;
      }
    },
    // setData() {
    //   if (this.formData.term == 0) {
    //     this.formData.term = "";
    //   }
    //   this.getShipment();
    // },
    // getShipment() {
    //   if (
    //     this.formData.shipmentMin == 0 &&
    //     this.formData.shipmentMax == 500000
    //   ) {
    //     this.formData.shipment = "<500000";
    //   } else if (
    //     this.formData.shipmentMin == 500000 &&
    //     this.formData.shipmentMax == 0
    //   ) {
    //     this.formData.shipment = ">500000";
    //   } else if (
    //     this.formData.shipmentMin == 0 &&
    //     this.formData.shipmentMax == 0
    //   ) {
    //     this.formData.shipment = "";
    //   }
    //   // if (this.formData.shipmentMin == 0) {
    //   //   if (this.formData.insuranceSizeM || this.formData.insuranceSizeL) {
    //   //     this.formData.shipment = "0";

    //   //     if (this.formData.shipmentMin == 0) {
    //   //       this.formData.exportValue = "1";
    //   //     } else {
    //   //       this.formData.exportValue = "0";
    //   //     }
    //   //   } else {
    //   //     this.formData.shipment = "<500000";
    //   //     this.formData.shipmenMin = 0;
    //   //     this.formData.shipmentMax = 500000;
    //   //   }
    //   // } else {
    //   //   this.formData.shipment = ">500000";
    //   //   this.formData.shipmentMin = 0;
    //   //   this.formData.shipmentMax = 0;
    //   // }
    // },
    sendData() {
      this.$emit("stepNow", this.formData);
    },
    selectTERAK(val) {
      if (val[val.length - 1] == 999) {
        this.formData.projectTERAK = [];
        this.optionsRef.forEach((gAll) => {
          !gAll.expire ? this.formData.projectTERAK.push(gAll.id) : "";
        });
        this.constSelect2 = JSON.parse(
          JSON.stringify(this.formData.projectTERAK)
        );
      } else {
        if (val[0] === 999 && val.length < this.optionsRef.length) {
          this.formData.projectTERAK.splice(0, 1);
          this.constSelect2 = JSON.parse(
            JSON.stringify(this.formData.projectTERAK)
          );
        } else if (this.constSelect2[0] === 999 && val[0] !== 999) {
          this.formData.projectTERAK = [];
          this.constSelect2 = JSON.parse(
            JSON.stringify(this.formData.projectTERAK)
          );
        } else {
          let fn = this.constSelect2.findIndex((a) => a === 999);
          if (val.length == this.optionsRef.length - 1 && fn === -1) {
            this.formData.projectTERAK = [];
            this.optionsRef.forEach((gAll) => {
              !gAll ? this.formData.projectTERAK.push(gAll.id) : "";
            });
            this.constSelect2 = JSON.parse(
              JSON.stringify(this.formData.projectTERAK)
            );
          }
        }
      }
      this.sendData();
    },
    statusSelectedSend2Channel() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.post("/ch/insurance/edit", obj)
        .then((res) => {})
        .catch((err) => {
          console.log("Error Status Send to channel", err.response.data);
        });
    },
  },
  computed: {
    ...mapState({
      token: (state) => state._token,
    }),
  },
};
</script>
