<template>
  <DefaultTemplate
    v-loading.lock="loadingData"
    element-loading-text="กำลังโหลดข้อมูล..."
    element-loading-spinner="el-icon-loading"
  >
    <div class="box-name-page">
      <div>
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          class="set-breadcrumb"
        >
          <el-breadcrumb-item :to="{ path: '/manage/export-insurance' }"
            >จัดการข้อมูลประกันการส่งออก</el-breadcrumb-item
          >
          <el-breadcrumb-item>แก้ไขข้อมูลประกันการส่งออก</el-breadcrumb-item>
        </el-breadcrumb>
        <p class="title mg-t-4">แก้ไขข้อมูลประกันการส่งออก</p>
      </div>
    </div>
    <div v-loading="loading && !loadingData" v-if="!loading">
      <el-form
        label-position="top"
        :model="form"
        class="set-form"
        :rules="rules"
        ref="form"
      >
        <Step1
          @stepNow="formDataOut"
          @optionsOut="optionsRef"
          :formData="form"
        />
        <Step2
          @insuranceEdit="insuranceEdit"
          :formData="form"
          :optionsInsurance="optionsInsurance"
          :loadingSave="loadingSave"
        />
      </el-form>
    </div>
  </DefaultTemplate>
</template>

<script>
import DefaultTemplate from "@/template/DefaultTemplate";
import Step1 from "@/components/export/edit/Step1";
import Step2 from "@/components/export/edit/Step2";
import { HTTP } from "@/service/axios";
import { TERAK } from "@/service/axios";
import jwt from "jsonwebtoken";
import crypto from "crypto";
const algorithm = "aes-256-ctr";
const secretKey = "vwPH9sdmpnVjrTIQCc7qSXs11lWhzFrT";
const iv = crypto.randomBytes(16);

export default {
  components: {
    DefaultTemplate,
    Step1,
    Step2,
  },
  computed: {
    _token() {
      return this.$store.state._token;
    },
  },
  data() {
    var validateSize = (rule, value, callback) => {
      if (
        !this.form.insuranceSizeS &&
        !this.form.insuranceSizeM &&
        !this.form.insuranceSizeL
      ) {
        callback(new Error("กรุณาเลือกขนาดธุรกิจ"));
      } else {
        callback();
      }
    };
    var validateDatePicker = (rule, value, callback) => {
      if (this.form.insuranceStart == "" && this.form.insuranceEnd == "") {
        callback(new Error("กรุณาเลือกวันที่"));
      } else {
        callback();
      }
    };

    var validTERAK = (rule, value, callback) => {
      let isExpire = [];
      this.form.projectTERAK.forEach((row) => {
        let checkId = this.optionsRefs.find(
          (e) => e.expire === true && e.id == row
        );
        if (typeof checkId != "undefined") {
          isExpire.push(checkId);
        }
      });

      if (isExpire.length > 0) {
        callback(new Error("กรุณานำโครงการที่หมดอายุออก"));
      } else {
        callback();
      }
    };
    var validtitleOne = (rule, value, callback) => {
      if (this.form.statusOne && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleTwo = (rule, value, callback) => {
      if (this.form.statusTwo && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleThree = (rule, value, callback) => {
      if (this.form.statusThree && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleFour = (rule, value, callback) => {
      if (this.form.statusFour && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleFive = (rule, value, callback) => {
      if (this.form.statusFive && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleSix = (rule, value, callback) => {
      if (this.form.statusSix && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleSeven = (rule, value, callback) => {
      if (this.form.statusSeven && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleEight = (rule, value, callback) => {
      if (this.form.statusEight && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    var validtitleNine = (rule, value, callback) => {
      if (this.form.statusNine && !value) {
        callback(new Error("กรุณากรอกชื่อหัวข้อ"));
      } else {
        callback();
      }
    };
    return {
      loadingData: true,
      optionsRefs: [],
      loadingSave: false,
      loading: true,
      idData: this.$route.params.id,
      form: {},
      rules: {
        titleOne: [{ validator: validtitleOne, trigger: ["blur", "change"] }],
        titleTwo: [{ validator: validtitleTwo, trigger: ["blur", "change"] }],
        titleThree: [
          { validator: validtitleThree, trigger: ["blur", "change"] },
        ],
        titleFour: [{ validator: validtitleFour, trigger: ["blur", "change"] }],
        titleFive: [{ validator: validtitleFive, trigger: ["blur", "change"] }],
        titleSix: [{ validator: validtitleSix, trigger: ["blur", "change"] }],
        titleSeven: [
          { validator: validtitleSeven, trigger: ["blur", "change"] },
        ],
        titleEight: [
          { validator: validtitleEight, trigger: ["blur", "change"] },
        ],
        titleNine: [{ validator: validtitleNine, trigger: ["blur", "change"] }],
        expTERAK: [{ validator: validTERAK, trigger: ["change"] }],
        insuranceProductId: [
          {
            required: true,
            message: "กรุณากรอก Insurance Product ID",
            trigger: ["blur", "change"],
          },
        ],
        insuranceName: [
          { required: true, message: "กรุณากรอกชื่อประกัน", trigger: "blur" },
        ],
        insuranceSize: [{ validator: validateSize, trigger: "change" }],

        datePicker: [{ validator: validateDatePicker, trigger: "change" }],
      },

      optionsInsurance: [],
    };
  },
  mounted() {
    this.getInsurance();
    this.getProductId();
  },
  methods: {
    getProductId() {
      HTTP.get(`crm/insurance/master`)
        .then((res) => {
          if (res.data.success) {
            this.optionsInsurance = res.data.obj;
          }
        })
        .catch((e) => {
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else {
            this.alertCatchError(e);
          }
        });
    },
    getInsurance() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`insurance/edit/${this.idData}`)
        .then((res) => {
          if (res.data.success) {
            this.form = {
              ...res.data.obj,
              files: [],
              delImg: "",
            };
            this.loading = false;
            this.loadingData = false;
            setTimeout(() => {
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
            }, 500);
          } else {
            if (res.data.status == 403) {
              this.$store.commit("SET_PAGE_ACTIVE", null);
              this.$router.push("/not-allowed");
            }
          }
        })
        .catch((e) => {
          this.loadingData = false;
          if (e.response.data.status == 401) {
            this.checkAuth();
          } else if (
            e.response.data.status == 404 ||
            e.response.data.status == 422
          ) {
            this.$message({
              message: "ไม่พบข้อมูลประกัน",
              type: "error",
              duration: 4000,
            });
            this.$router.push(`/manage/export-insurance`);
          } else {
            this.alertCatchError(e);
          }
        });
    },
    optionsRef(data) {
      this.optionsRefs = data;
    },
    formDataOut(data) {
      this.form = data;
    },
    async insuranceEdit(data) {
      this.form = data;
      this.$refs["form"].validate().then(async (valid) => {
        if (valid) {
          this.loadingSave = true;
          let resImg = "";
          if (this.form.files.length > 0) {
            if (typeof this.form.files[0].file != "undefined") {
              var formDataTERAK = new FormData();
              formDataTERAK.append("file", this.form.files[0].file.raw);
              var token = jwt.sign(
                { system: "CFOE2021" },
                "Y2weQEzYjje3LRDiwAUiX1U2r",
                { expiresIn: "5m" }
              );

              TERAK.defaults.headers.common.Authorization = token;
              const res = await TERAK.post(`cfoe/file`, formDataTERAK);
              if (res.data.success) {
                resImg = res.data.filename;
              }
            }
          }
          let projectTERAK = this.form.projectTERAK.findIndex((a) => a === 999);
          if (projectTERAK > -1) {
            this.form.projectTERAK.splice(projectTERAK, 1);
          }          
          let forShipment = [];
          let forExportValue = [];
          let forTerm = [];
          let forProjectTERAK = [];

          this.form.shipment.forEach((row) => {
            forShipment.push(row.toString());
          });
          
          this.form.exportValue.forEach((row) => {
            forExportValue.push(row.toString());
          });
          
          this.form.term.forEach((row) => {
            forTerm.push(row.toString());
          });
          
          this.form.projectTERAK.forEach((row) => {
            forProjectTERAK.push(row.toString());
          });

          let obj = {
            sendToCH: this.form.sendToCH,
            insuranceSizeS: this.form.insuranceSizeS,
            insuranceSizeM: this.form.insuranceSizeM,
            insuranceSizeL: this.form.insuranceSizeL,
            insuranceName: this.form.insuranceName,
            exportValue: forExportValue,
            shipment: forShipment,
            term: forTerm,
            insuranceStart: this.form.insuranceStart,
            insuranceEnd: this.form.insuranceEnd,
            insuranceOverview: this.form.insuranceOverview,
            insuranceCondition: this.form.insuranceCondition,
            protectedRisk: this.form.protectedRisk,
            converageRate: this.form.converageRate,
            insuranceCost: this.form.insuranceCost,
            insuranceClaimPay: this.form.insuranceClaimPay,
            serviceRequestProcess: this.form.serviceRequestProcess,
            specialCondition: this.form.specialCondition,
            delImg: this.form.delImg != "" ? this.form.delImg : "",
            projectTERAK: forProjectTERAK,
            insuranceProductId: this.form.insuranceProductId,
            image: resImg == "" ? this.form.image : resImg,
            titleOne: this.form.titleOne,
            titleTwo: this.form.titleTwo,
            titleThree: this.form.titleThree,
            titleFour: this.form.titleFour,
            titleFive: this.form.titleFive,
            titleSix: this.form.titleSix,
            titleSeven: this.form.titleSeven,
            titleEight: this.form.titleEight,
            titleNine: this.form.titleNine,
            statusOne: this.form.statusOne,
            statusTwo: this.form.statusTwo,
            statusThree: this.form.statusThree,
            statusFour: this.form.statusFour,
            statusFive: this.form.statusFive,
            statusSix: this.form.statusSix,
            statusSeven: this.form.statusSeven,
            statusEight: this.form.statusEight,
            statusNine: this.form.statusNine,

            detailsNine: this.form.detailsNine,
          };

          HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
          HTTP.put(`insurance/${this.idData}`, obj)
            .then((res) => {
              if (res.data.success) {
                this.$message({
                  message: "แก้ไขข้อมูลประกันสำเร็จ",
                  type: "success",
                  duration: 4000,
                });
                this.$router.push(`/manage/export-insurance`);
              } else {
                if (res.data.status == 204) {
                  this.loadingSave = false;
                  this.$message({
                    message: "ชื่อข้อมูลประกันซ้ำ",
                    type: "error",
                    duration: 4000,
                  });
                }
              }
            })
            .catch((e) => {
              if (e.response.data.status == 401) {
                this.checkAuth();
              } else {
                this.alertCatchError(e);
              }
            });
        }
      });
    },
  },
};
</script>
